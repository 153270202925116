const Reducer = (state, action) => {
    switch (action.type) {
      case "setTranslations":
        return {
          ...state,
          translations: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default Reducer;
  