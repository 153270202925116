import React, { useReducer } from "react";
import Reducer from "./Reducer";
import PropTypes from "prop-types";

// Initial the global state
const initialState = {
  translations:{}
};

/**
 * @description the context for the global state
 */
export const Context = React.createContext(initialState);

/**
 * 
 * @returns The children element inside the context provider.
 */
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export default Store;

Store.propTypes = {
  children: PropTypes.node,
};
