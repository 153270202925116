import React from 'react'
import './Loader.css'
function Loader() {
    return (
        <>
            <div className="loader-wrapper">
                <div className='loading' style={{
                    background:`url('${process.env.PUBLIC_URL}/luiss_logo_animation.gif') 50% 50% no-repeat #ffffff`
                }}>
                </div>
            </div>
        </>
    );
}

export default Loader;